import { Palette as MuiPalette } from '@mui/material/styles';
import { primary, secondary, error, warning, success, common, text, info } from '@/constants/color';

export const Palette: Partial<MuiPalette> = {
  primary,
  secondary,
  error,
  warning,
  success,
  common,
  info,
  text,
};
