import { CommonColors, PaletteColor, TypeText } from '@mui/material';
import { Color } from '../types/theme.ts';

const DEFAULT_COLOR = '#000';
export const blue: Color = {
  1: '#e6f7ff',
  2: '#bae7ff',
  3: '#91d5ff',
  4: '#69c0ff',
  5: '#40a9ff',
  6: '#1890ff',
  7: '#096dd9',
  8: '#0050b3',
  9: '#003a8c',
  10: '#002766',
};

export const grey: Color = {
  1: '#fafafa',
  2: '#f5f5f5',
  3: '#f0f0f0',
  4: '#d9d9d9',
  5: '#bfbfbf',
  6: '#8c8c8c',
  7: '#595959',
  8: '#262626',
  9: '#141414',
  10: '#000000',
  A1: '#ffffff',
  A2: '#434343',
  A3: '#1f1f1f',
};

export const gold: Color = {
  1: '#fffbe6',
  4: '#ffd666',
  6: '#faad14',
  8: '#ad6800',
  10: '#613400',
};

export const green: Color = {
  1: '#f6ffed',
  4: '#95de64',
  6: '#52c41a',
  8: '#237804',
  10: '#092b00',
};

export const red: Color = {
  1: '#fff1f0',
  4: '#ff7875',
  6: '#f5222d',
  8: '#a8071a',
  10: '#5c0011',
};

export const error: PaletteColor = {
  main: '#D32F2F',
  dark: '#a8071a',
  light: '#fff1f0',
  contrastText: '#fff',
};

export const warning: PaletteColor = {
  main: '#EF6C00',
  dark: '#ad6800',
  light: '#fffbe6',
  contrastText: '#fff',
};

export const success: PaletteColor = {
  main: '#52c41a',
  dark: '#237804',
  light: '#f6ffed',
  contrastText: '#fff',
};

export const info: PaletteColor = {
  main: '#13C2C2',
  dark: '#13C2C2',
  light: '#13C2C2',
  contrastText: '#fff',
};

export const primary: PaletteColor = {
  main: '#1890ff',
  dark: '#096dd9',
  light: '#e6f7ff',
  contrastText: '#fff',
};

export const secondary: PaletteColor = {
  main: '#262626',
  dark: '#00000',
  light: '#8c8c8c',
  contrastText: '#fff',
};
export const brand: PaletteColor = {
  main: '#58ACFA',
  dark: '#2178b1',
  light: DEFAULT_COLOR,
  contrastText: DEFAULT_COLOR,
};

//
export const common: CommonColors = {
  white: '#FFFFFF',
  black: '#000000',
};

export const text: TypeText = {
  primary: '#262626',
  secondary: '#8c8c8c',
  disabled: 'rgba(0, 0, 0, 0.26)',
};

export const contractColor = {
  noContract: '#5CC8BE',
  total: '#FF715B',
  year3: '#29CC39',
  year5: '#AF52DE',
  year7: '#357AF6',
  purchase: '#FF715B',
};

export const branchColor = {
  dallas: '#3361FF',
  austin: '#5856D6',
  houston: '#5CC8BE',
};

export const chipColor = {
  info: '#BFE1F6',
  success: '#D4EDBC',
  error: '#FFCFC9',
  default: '#E5E5E5',
};
