import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import TagManager from 'react-gtm-module';

import './index.css';
import { GlobalStyles } from '@mui/material';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import theme from '@/constants/theme';

const App = lazy(() => import('./App.tsx'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 0,
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
      throwOnError: false,
    },
    mutations: {
      throwOnError: false,
    },
  },
});

const globalStyles = (
  <GlobalStyles
    styles={{
      '*': {
        '&::-webkit-scrollbar': {
          width: `9px`,
          height: `9px`,
          backgroundColor: `transparent`,
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#dce1e5',
          borderRadius: `8px`,
          backgroundClip: `padding-box`,
          WebkitBackgroundClip: `padding-box`,
          border: `2px solid transparent`,
          marginRight: `2px`,
          ':hover': {
            backgroundColor: `#b1b5ba`,
            backgroundClip: `unset`,
            WebkitBackgroundClip: `unset`,
          },
          ':active': {
            backgroundColor: `#878d94`,
          },
        },
      },
    }}
  />
);

if (import.meta.env.VITE_GTM_ID && import.meta.env.MODE !== 'localhost') {
  TagManager.initialize({
    gtmId: import.meta.env.VITE_GTM_ID,
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <RecoilRoot>
          <SnackbarProvider autoHideDuration={2000}>
            <Suspense fallback={<div />}>
              {globalStyles}
              <App />
            </Suspense>
          </SnackbarProvider>
        </RecoilRoot>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
