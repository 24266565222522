import { createTheme } from '@mui/material/styles';
import { koKR } from '@mui/x-date-pickers/locales';
import { Palette } from '@constants/palette.ts';
import { gold, blue, green, red, grey, primary, brand, text } from '@/constants/color';

const theme = createTheme(
  {
    typography: {
      fontFamily: "'Inter', 'Noto Sans KR', sans-serif;",
      htmlFontSize: 18,
      fontSize: 14,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        xxl: 1920,
      },
    },
    palette: Palette,
    mixins: {
      toolbar: {
        minHeight: '60px',
      },
      sidebar: {
        width: '256px',
      },
      header: {
        height: window.location.pathname.startsWith(`/embedded`) ? `0px` : `100px`,
      },
      hiddenSidebar: {
        width: '65px',
      },
      aiPanel: {
        width: '330px',
      },
      section: {
        height: `calc(100vh - ${
          window.location.pathname.startsWith(`/embedded`) ? `0px` : `100px`
        })`,
        backgroundColor: '#F9F9F9',
      },
    },
    components: {
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: '10px',
          },
        },
      },
      MuiRating: {
        styleOverrides: {
          root: {
            color: primary.main,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: 'inherit',
            border: '1px solid #e6ebf1',
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          title: {
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '160%',
            letterSpacing: '0.15px',
          },
          root: {
            alignItems: 'center',
            padding: '8px 16px',
            minHeight: 34,
          },
          action: {
            margin: 0,
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: '0px 16px',
          },
        },
      },
      MuiFormControl: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiRadio: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiSelect: {
        defaultProps: {
          displayEmpty: true,
          size: 'small',
        },
      },
      MuiInput: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: {
            height: '35px',
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            borderCollapse: 'separate',
          },
        },
      },

      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              backgroundColor: '#F0F0F0',
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            '& button': {
              fontSize: 'inherit',
              textTransform: 'capitalize',
              backgroundColor: '#EBEBEB',
              color: `inherit`,
            },
            '& .Mui-selected': {
              backgroundColor: 'inherit',
            },
          },
        },
      },
      MuiToggleButtonGroup: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: {
            backgroundColor: '#F8F8FF',
            borderRadius: '15px',
          },
        },
      },
      MuiToggleButton: {
        defaultProps: {
          size: 'small',
          color: 'primary',
        },
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderRadius: '12px !important',
            padding: '5px 12px',
            border: 0,
            margin: '8px',
            '&.MuiToggleButton-primary.Mui-selected': {
              backgroundColor: primary.main,
              color: 'white',
            },
          },
        },
      },
      MuiFormGroup: {
        styleOverrides: {
          root: {
            height: '100%',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            '& .MuiDialog-paper': {
              minWidth: '400px',
            },
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            borderTop: `1px solid ${grey['4']}`,
          },
        },
      },

      MuiButton: {
        defaultProps: {
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiAccordion: {
        defaultProps: {
          defaultExpanded: true,
        },
        styleOverrides: {
          root: {
            '&.Mui-expanded': { margin: '0' },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          content: {
            margin: '0px 0 !important',
            minHeight: 50,
            display: 'flex',
            alignItems: 'center',
            '&  .MuiTypography-root': {
              lineHeight: '1.6',
            },
          },
          root: {
            '&.Mui-expanded': { minHeight: '50px' },
            minHeight: '50px',
            color: text.primary,
            fontSize: '16px',
            fontWeight: 700,
            borderBottom: '1px solid  #F0F0F0',
          },
        },
      },
      MuiAccordionActions: {
        styleOverrides: {
          root: {
            borderTop: '1px solid  #F0F0F0',
            padding: 'unset',
            height: '40px',
          },
        },
      },
    },
  },
  koKR,
  {
    color: { gold, blue, green, red, grey, brand },
    size: {
      small: '40px',
    },
  },
);
export default theme;
